import helpTriggerTemplate from '/Areas/Admin/Content/src/_partials/help-trigger/templates/help-trigger.html';
(function (ng) {
  'use strict';

  /*
   * <help-trigger class="ng-cloak" data-title="фывфывфывфыв">
          <strong>sdasfasdas</strong><br />
          <i>asdfsdasd</i>
      </help-trigger>
   */
  var increment = 1;
  ng.module('helpTrigger').directive('helpTrigger', ['$sce', '$templateRequest', '$compile', '$templateCache', '$parse', 'urlHelper', function ($sce, $templateRequest, $compile, $templateCache, $parse, urlHelper) {
    return {
      controller: 'HelpTriggerCtrl',
      bindToController: true,
      controllerAs: '$ctrl',
      transclude: true,
      //scope: {
      //    title: '@',
      //    useTemplate: '<?'
      //},
      scope: true,
      link: function (scope, element, attrs, ctrl, transclude) {
        var clone;
        ctrl.title = attrs.title;
        ctrl.placement = attrs.placement || 'auto right';
        ctrl.useTemplate = attrs.useTemplate ? attrs.useTemplate === 'true' : false;
        ctrl.helpAppendToBody = attrs.helpAppendToBody != null ? $parse(attrs.helpAppendToBody)(scope) : true;
        ctrl.classes = attrs.classes != null ? $parse(attrs.classes)(scope) : '';
        ctrl.pathSVGSprite = attrs.pathSVGSprite != null ? attrs.pathSVGSprite : null;
        // remove when in admin will add svg sprite.
        ctrl.isInfoIcon = attrs.isInfoIcon != null ? attrs.isInfoIcon : null;
        $templateRequest(helpTriggerTemplate).then(function (tpl) {
          var innerEl = document.createElement('div'),
            uiPopoverEl,
            clone,
            container;
          var childScope = scope.$new();
          ctrl.innerPopoverContentClass = 'js-help-trigger-content-' + increment;
          innerEl.innerHTML = tpl;
          uiPopoverEl = innerEl.querySelector('.help-trigger-icon');
          container = document.createElement('div');
          transclude(childScope, function (cloneElements, innerScope) {
            clone = cloneElements;
            for (var i = 0; i < cloneElements.length; i++) {
              container.appendChild(cloneElements[i]);
            }
          });
          uiPopoverEl.setAttribute('popover-class', ctrl.innerPopoverContentClass + ' ' + ctrl.classes);
          if (ctrl.useTemplate === true) {
            uiPopoverEl.setAttribute('uib-popover-template', "'helpTrigger_" + increment + ".html'");
            $templateCache.put('helpTrigger_' + increment + '.html', container.outerHTML);
          } else {
            uiPopoverEl.setAttribute('uib-popover-html', '$ctrl.content');
            ctrl.content = $sce.trustAsHtml(ng.element('<div />').append(clone).html());
          }
          element[0].innerHTML = innerEl.innerHTML;
          var childs = element[0].children;
          $compile(childs)(scope);
          increment++;
        });
      }
    };
  }]);
})(window.angular);